import Link from "next/link";
import { InlineLink } from "../ReusableStyledComponents/InlineLink";
import { APPLICATION_ROUTES } from "../../types/routes";
import { BannerDetails } from "../BannerDetails";

const getOrderGrammarConnector = (hasFiltersEnabled: boolean) => {
    if (!hasFiltersEnabled) return "has been";
    return "and it's been";
};

interface CollectionBannerDetailsProps {
    isManuallyOrdered: boolean;
    hasFiltersEnabled: boolean;
    onCollectionUpdateRules?: () => void;
    collectionSlug?: string;
}

export const CollectionBannerDetails = ({
    isManuallyOrdered,
    hasFiltersEnabled,
    onCollectionUpdateRules,
    collectionSlug
}: CollectionBannerDetailsProps) => {
    if (!isManuallyOrdered && !hasFiltersEnabled) return <></>;

    return (
        <BannerDetails>
            This collection{" "}
            {hasFiltersEnabled ? (
                <InlineLink
                    onClick={() => {
                        onCollectionUpdateRules && onCollectionUpdateRules();
                    }}
                >
                    is auto-updating
                </InlineLink>
            ) : (
                <></>
            )}
            {isManuallyOrdered ? (
                <>
                    {" "}
                    {getOrderGrammarConnector(hasFiltersEnabled)}{" "}
                    <Link href={`${APPLICATION_ROUTES.COLLECTION_SORT.replace("[collectionId]", collectionSlug || "")}`} legacyBehavior passHref>
                        <InlineLink> manually ordered</InlineLink>
                    </Link>
                </>
            ) : (
                <></>
            )}
            .
        </BannerDetails>
    );
};

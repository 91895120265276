import { ArticlePreviewResponse, Collection, ModifyCollectionContent, PinArticleToCollectionBodyParams } from "authory-api-types/dist/types";
import toast from "react-hot-toast";
import { useRouter } from "next/router";
import { PropsWithChildren, useContext, useState } from "react";
import { APPLICATION_ROUTES } from "../../types/routes";
import { V3Dropdown } from "../Dropdown";
import { DropdownOptionCTA } from "../DropdownOptionCTA";
import { V3IconGrab, V3IconPin, V3IconImage, V3IconArticle, V3IconRemoveFromCollection } from "../Icons";
import { V3ProfileEditModeWrapper } from "./V3ProfileList.styles";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { PinArticleToCollection } from "../../api/collections";
import { V3GenericErrorHandler } from "../../api/error-handler";
import { Queries } from "../../types/queries";
import { getAuthenticatedUserClient } from "../../utils/getAuthenticatedUser";
import { CookieContext } from "../../context/cookieContext";
import { V3EditContentSEOArticleContainer } from "../V3EditContentSEO/V3EditContentSEOArticleContainer";
import { getAddRemoveItemFromCollectionMutation } from "../../utils/mutation-utils/collections/AddRemoveItemFromCollection";
import { DropdownHRElemt } from "../ReusableStyledComponents/DropdownHRElements";
import { getContentCopyLabel } from "../../utils/article-text-utils";
import { V3ModalCollectionOrderWarning } from "../V3ModalCollectionOrderWarning";
import { CollectionItemOrder } from "authory-api-types/dist/enums";
import Link from "next/link";

interface ItemDropdownContainerProps {
    item: ArticlePreviewResponse;
    editMode: boolean;
    collection: Collection;
    setIntercomTarget?: boolean;
}

export const ItemDropdownContainer = ({
    children,
    item,
    editMode,
    collection,
    setIntercomTarget = false
}: PropsWithChildren<ItemDropdownContainerProps>) => {
    const router = useRouter();
    const queryClient = useQueryClient();
    const token = useContext(CookieContext);
    const { authenticatedUser } = getAuthenticatedUserClient(token, router, true);

    const [showChangeOrderWarningModal, setShowChangeOrderWarningModal] = useState(false);

    const [SEOModalOpen, setSEOModalOpen] = useState(false);

    const pinArticleToCollection = useMutation({
        mutationFn: PinArticleToCollection,
        onError: V3GenericErrorHandler(toast.error),
        onSuccess: async (_data, variables) => {
            if (variables.params.article) toast.success("Item has been pinned");
            else toast.success("Item is no longer pinned");
            queryClient.invalidateQueries({
                queryKey: [Queries.Content, token, authenticatedUser.data!.slug, { collection: collection.canonicalSlug }]
            });
        }
    });

    const onPinHandler = (itemSlug: string, pinning: boolean) => {
        if (!token || !authenticatedUser.data) return;

        const params = new PinArticleToCollectionBodyParams();
        params.article = pinning ? itemSlug : "";

        pinArticleToCollection.mutate({
            token,
            userSlug: authenticatedUser.data?.slug,
            params,
            collectionId: collection.canonicalSlug
        });
    };

    // Add item to collection mutation
    const addItemToCollectionMutation = getAddRemoveItemFromCollectionMutation(queryClient);

    const addOrRemoveItemManuallyFromCollection = async (add: boolean, canonicalSlug: string) => {
        if (!token || !authenticatedUser.data?.slug) return;

        const payload: ModifyCollectionContent = {
            articleIds: [canonicalSlug]
        };

        // Add item to collection
        await addItemToCollectionMutation.mutateAsync({
            token,
            userSlug: authenticatedUser.data?.slug as string,
            collectionSlug: collection.canonicalSlug,
            payload,
            add: add
        });

        toast.success("Item has been removed!");

        queryClient.invalidateQueries({ queryKey: [Queries.User] });
    };

    const onSelectOrder = () => {
        router.push(APPLICATION_ROUTES.COLLECTION_SORT.replace("[collectionId]", collection.canonicalSlug));
    };

    if (!editMode) return <>{children}</>;

    return (
        <>
            <V3Dropdown
                clientPointEnabled
                title="Item settings"
                renderReferenceElement={(active) => {
                    return (
                        <V3ProfileEditModeWrapper $showShadow={active} data-intercom-target={setIntercomTarget ? "ProfileListItem" : undefined}>
                            {children}
                        </V3ProfileEditModeWrapper>
                    );
                }}
                renderPopperElement={(_, setActive) => {
                    return (
                        <>
                            <DropdownOptionCTA
                                label={`View ${getContentCopyLabel(item.type)} details`}
                                icon={<V3IconArticle />}
                                href={`${APPLICATION_ROUTES.CONTENT}/${item.canonicalSlug}`}
                            />
                            <DropdownHRElemt>
                                <hr />
                            </DropdownHRElemt>
                            <DropdownOptionCTA
                                label={item.pinned ? "Unpin" : "Pin"}
                                icon={<V3IconPin />}
                                onClick={() => {
                                    setActive(false);
                                    onPinHandler(item.canonicalSlug!, !item.pinned);
                                }}
                            />
                            <div style={{ display: "none" }}>
                                <Link href={`${APPLICATION_ROUTES.COLLECTION_SORT.replace("[collectionId]", collection.canonicalSlug)}`}></Link>
                            </div>
                            <DropdownOptionCTA
                                label={"Change order"}
                                icon={<V3IconGrab />}
                                onClick={() => {
                                    setActive(false);

                                    // show confirmation modal when switching between manual and dates ordering
                                    if (collection.itemOrder !== CollectionItemOrder.Manual) {
                                        setShowChangeOrderWarningModal(true);
                                    } else {
                                        onSelectOrder();
                                    }
                                }}
                            />
                            <DropdownOptionCTA
                                label={"Edit preview"}
                                icon={<V3IconImage />}
                                onClick={() => {
                                    setActive(false);
                                    setSEOModalOpen(true);
                                }}
                            />
                            <DropdownOptionCTA
                                label={"Remove from collection"}
                                icon={<V3IconRemoveFromCollection stroke={"#ABB0BA"} width={20} height={20} style={{ marginRight: -4 }} />}
                                onClick={() => {
                                    addOrRemoveItemManuallyFromCollection(false, item.canonicalSlug!);
                                }}
                            />
                        </>
                    );
                }}
            />
            {SEOModalOpen && <V3EditContentSEOArticleContainer modalOpen={SEOModalOpen} setModalOpen={setSEOModalOpen} article={item} />}
            {showChangeOrderWarningModal && (
                <V3ModalCollectionOrderWarning collection={collection} setModalOpen={setShowChangeOrderWarningModal} onSubmit={onSelectOrder} />
            )}
        </>
    );
};
